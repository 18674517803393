import React from "react"

import Layout from "../../components/layout.tsx"

import FeedbackCard from "../../components/feedback_card.tsx"
import AboutUs from "../../components/about_us.tsx"

const RosenProject = ({ data, location }) => {
  return (
    <Layout location={location} title="Rosen NFTs">
      <div className="rosen-project-hero" />
      <div className="lg:w-1/2 mx-16 lg:ml-12 -mt-24 ">
        <h1>Rosen NFT drop</h1>
        <p className="lead">
          This project involved working with{" "}
          <a href="https://www.treum.io/" target="_blank" rel="noreferrer">
            Treum
          </a>
          , the talented team behind{" "}
          <a href="https://eulerbeats.com/" target="_blank" rel="noreferrer">
            Euler Beats
          </a>{" "}
          (the project that combined NFTs and generated music on the Ethereum
          blockchain). For this project we designed and built a custom sales
          site for the famous word artist{" "}
          <a href="https://jonathanrosen.com/" target="_blank" rel="noreferrer">
            Jonathan Rosen
          </a>
          .
        </p>
      </div>
      <div className="lg:w-1/2 mx-16 my-8 lg:mx-auto mt-16 text-black">
        <h3>Art that choses you</h3>
        <p>
          The design needed to match Jonathan&#39;s unique visual style to his
          art. The implemenation of the sales site and NFT contracts needed to
          be as solid as the contracts and sales app built for Euler Beats. It
          also created a new type of purchasing system for NFTs where the NFT
          artwork was chosen for you at random. There was no way to pick what
          phrases you would get ahead of time. This made for a very exciting and
          unique purchasing experience.
        </p>
        <h3>Nasdaq tower</h3>
        <p>
          Promotion of this project was very unique. The NFTs that were sold
          through the site were also displayed on the Nasdaq video tower in New
          York&#39;s Time Square.
        </p>
        <h3>For more information</h3>
        <ul className="list-inside p-2 list-disc">
          <li>
            <a
              href="https://nft.jonathanrosen.com/"
              target="_blank"
              rel="noreferrer"
            >
              The new redesigned site
            </a>
          </li>
          <li>
            <a
              href="https://decrypt.co/72995/artist-jonathan-rosen-and-treum-collaborate-on-massive-nft-drop"
              target="_blank"
              rel="noreferrer"
            >
              Press about the drop and the Nasdaq tower video board
            </a>
          </li>
          <li>
            <a
              href="https://www.seegreatart.art/jonathan-rosens-text-based-art-taking-over-nasdaq-marquee-for-nft-drop/"
              target="_blank"
              rel="noreferrer"
            >
              Press about the art and the use of NFTs
            </a>
          </li>
          <li>
            <a
              href="https://opensea.io/collection/rosenbigdrop"
              target="_blank"
              rel="noreferrer"
            >
              View the full collection on OpenSea
            </a>
          </li>
        </ul>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="m-auto order-last mt-6 lg:order-first lg:mr-10 lg:ml-auto">
          <FeedbackCard location="projects/rosen" />
        </div>
        <div className="mt-8 ml-1 mr-10">
          <AboutUs />
        </div>
      </div>
    </Layout>
  )
}

export default RosenProject
